import React, {useContext, useState, useEffect} from 'react'
import { motion } from 'framer-motion'
import { MaterialContext } from '../context/MaterialContext'

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
}

const item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 }
}

function Testing({title, slideData}) {

    const { materialData } = useContext(MaterialContext)
    const [ pageItems, setPageItems ] = useState([])

      useEffect(() => {
        if(materialData.length !== 0){
          slideData.forEach((title) => {
            let item = materialData.filter(x => x.slug === title )
            setPageItems((pageItems) => [...pageItems, item[0]])   
          })
        };
      }, [materialData, slideData]);

      useEffect(() => {
          setPageItems([]);
      }, [title]);

    return (
      <>
      <h2>{title}</h2>
      {pageItems.length !== 0 &&
              <motion.div 
              initial="hidden"
              animate="show"
              variants={container}>
                
                {pageItems.map((data,i) => <motion.h6 key={i} variants={item}>{data.title} - {data.description}</motion.h6>)} 
      
              </motion.div>
      }
      </>
    )
}

export default Testing

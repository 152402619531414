import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Slide from "../components/Slide";
import PresentationMode from "../components/PresentationMode";
import useEventListener from "../hooks/useEventListener";
//import { useTotalPages } from "../context/totalPagesContext"
//import { useMode } from "../context/ModeContext";
import { useCurrentSlide } from "../context/CurrentSlideContext";
import { Storage } from "../hooks/useStorage";
import presentationData from '../data/presentationData.json'

export default function SlidePage({ children, next }) {
  const {
    currentSlide,
    setSlide,
    steps,
    currentStep,
    setCurrentStep,
    clearSteps,
  } = useCurrentSlide();
  
  const router = useHistory();
  const totalPages = presentationData[0].pages.length;
  //const { mode, setMode } = useMode();

  const NEXT = [13, 32, 39, 38];
  const PREV = [37, 40];
  //const PRESENTER = 80;
  let slideCount = 0;

  const navigate = ({ keyCode }) => {
        // Handle Previous page
    if (PREV.indexOf(keyCode) !== -1 && currentSlide === 0) {
      if (router.query && router.pathname) {
        if (router.pathname > 1) {
          router.push(`${parseInt(router.pathname, 10) - 1}#999`);
        }
      }
      return false;
    }

    // Handle next page
    if (NEXT.indexOf(keyCode) !== -1 && currentSlide === slideCount && currentSlide !== totalPages-1) {
      if (router.query && router.pathname && next ) {
        router.push(`${next}`);
      }
      return false;
    }

    // Handle slide changes
    if (NEXT.indexOf(keyCode) !== -1 && currentSlide !== totalPages-1) {
      // Do we have Steps inside the slide? Navigate those first
      if (steps.length > 0 && currentStep < steps.length - 1)
        return setCurrentStep((prevStep) => prevStep + 1);

      // Otherwise go to next slide
      setSlide((prevState) => {
        return prevState + 1;
      });
      clearSteps();
    } else if (PREV.indexOf(keyCode) !== -1) {
        
      // Do we have Steps inside the slide? Navigate those first
      if (steps.length > 0 && currentStep !== 0)
        return setCurrentStep((prevStep) => prevStep - 1);

      // Otherwise go to prev slide
      setSlide((prevState) => {
        router.push(`#${currentSlide}`)
        return prevState - 1;
      });
      clearSteps();
    }
  };

  useEffect(() => {
    router.push(`#${currentSlide}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide, router.pathname]);

  useEventListener("keydown", navigate);

  const renderSlide = () => {
    let generatedSlides = [];
    let generatorCount = 0;

    // Filter down children by only Slides
    React.Children.map(children, (child) => {
      // Check for <hr> element to separate slides
      const childType = child && child.props && (child.props.mdxType || []);
      if (childType && childType.includes("hr")) {
        generatorCount += 1;
        return;
      }

      // Check if it's a SpeakerNotes component
      if (childType && !childType.includes("SpeakerNotes")) {
        // Add slide content to current generated slide
        if (!Array.isArray(generatedSlides[generatorCount])) {
          generatedSlides[generatorCount] = [];
        }
        generatedSlides[generatorCount].push(child);
      }
    });

    // Get total slide count
    slideCount = generatorCount;

    // Return current slide
    if (currentSlide === 999) {
      router.push(
        router.pathname,
        `${router.pathname}#${slideCount}`,
        { shallow: true }
      );
      setSlide(slideCount);
    }
    return <Slide>{generatedSlides[currentSlide]}</Slide>;
  };

  return (
<>
      <Storage />
      <PresentationMode
        currentSlide={currentSlide}
      >
        <div id="slide" style={{ width: "100%" }}>
          {renderSlide()}
        </div>
      </PresentationMode>
</>
  );
}

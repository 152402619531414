import React from 'react'
import { MDXProvider as MdxProvider } from "@mdx-js/react";
import SlidePage from "../layouts/SlidePage";
import Step from "./Step";
import Steps from "./Steps";

function MDXProvider({ children }) {

  const mdComponents = {
    SlidePage,
    Step,
    Steps,
  };

  return (
    <MdxProvider components={mdComponents}>{children}</MdxProvider>
  )
}

export default MDXProvider

import React from 'react'
import background from '../images/background.jpg'
 
export default ({ children }) => (
  
  <div
    style={{
      width: '100vw',
      height: '100vh',
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      padding: '50px 10px',
      display: 'flex',
      justifyContent:'center',
      alignItems:'center',
      flexDirection:'column',
    }}>
    {children}
  </div>
)
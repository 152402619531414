import React from "react";
import { motion } from "framer-motion";

function SVGAnimation() {
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 1920 1920"
      width="700"
      height="700"
      style={{zIndex:1}}
    >
      <g>
        <motion.path     
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={8}
          initial={{
            opacity: 0,
            pathLength: 0,

          }}
          animate={{
            opacity: 1,
            pathLength: 1,
            filter: "drop-shadow(10px 10px 8px #414141)",
            transition: {
              duration: 2,
              delay:1,
            },
          }}

          d="M404.7,290.8l-107.5,30.7c-5.5,1.4-9.8,5.5-11.5,10.9l-12.3,40.7c-0.9,2.9-2.5,5.6-4.8,7.7l-14.5,13.7
          c-1.1,1-1.9,2.4-2.2,3.9l-11.8,53c-0.5,2.4-1.3,4.7-2.4,6.9l-35,73.9c-1.7,3.7-2.3,7.8-1.7,11.8l-5.3,69.6l21.3,27.2l-55.1,53.3
          l51.3,94.4l65.9,183.1l50,184.1l6.5,35.5c0,0,28,114.4,63.1,234.9c0,0,15.4,25,49.5,18.5c34.1-6.5,62.5-13.1,62.5-13.1l8.7,7.6"/>
	<motion.path 
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={8}

      initial={{
        opacity: 0,
        pathLength: 0,
        
      }}
      animate={{
        opacity: 1,
        pathLength: 1,
        filter: "drop-shadow(10px 10px 8px #414141)",
        transition: {
          duration: 2,
          delay: 2
        },
      }}

    
      d="M448.2,275.3l25.4-7.3c0,0,41-32,62.5-41.8c21.4-9.8,53.8-19.3,53.8-19.3s50.1,18.5,59.2,20.3
      c9.1,1.8,5.4,13.8,5.4,13.8l32.7,25.8c0,0,87.5,82.5,106.1,90.4c18.5,8,69,38.9,70.5,38.9s22.2,10.9,22.2,10.9s9.4,4.7,26.2,0
      s72.3,13.1,72.3,13.1s23.6,11.6,46.9,51.9c23.2,40.3,56.3,68.7,67.9,73.4c11.6,4.7,118.4,62.8,118.4,62.8s55.9,36,73,49
      c17.1,13.1,56.3,32.7,73.4,50.9s37.1,38.5,57.4,82.8s41.4,115.5,42.9,131.9c0,0,2.2,25.8,0,37.8c-2.2,12-12,53.4-12,53.4"/>
              </g>
    </motion.svg>
  );
}

export default SVGAnimation;

import React, { useState, useContext } from "react";
import { MaterialContext } from "../context/MaterialContext";

function Search() {

  const { changeItemData } = useContext(MaterialContext);

  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage ] = useState(false)

  const goSearch = () => {
    fetch(`https://matlib.co/wp-json/ml/v1/materials/filter?search=${search}`)
      .then((response) => {
        if (response.ok) {
          setLoading(true);
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setResults(data);
        if(data.length !== 0) setMessage(false)
        else setMessage(true)
      })
      .catch((error) => {
        console.log("there was an error", error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    goSearch();
  };

  const handleItem = (data) => {
    changeItemData(data)
  }

  return (
    <>
    
    <div className="search">
      <form onSubmit={handleSearch}>
        <input
          type="text"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="search"
          style={{marginBottom:'10px'}}
        />
      </form>
      {loading && "loading..."}
      {error && "error loading the info."}
      {message && results.length === 0 && `no results for ${search}`}
      {results &&
        results.map((data) => (
          <div className="search-results" key={data.id}>
           <div className="search-results-item" onClick={() => handleItem(data)}>{data.title.toLowerCase()}</div> 
          </div>
        ))}
    </div>
   
    </>
  );
}

export default Search;

import React, { useState, useEffect } from "react";
import useEventListener from "../hooks/useEventListener";
//import { Link } from 'react-router-dom'
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 280 : -280,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 280 : -280,
      opacity: 0,
    };
  },
};

function Directions() {
  const [keyPress, setKeyPress] = useState("");
  const [keyName, setKeyName] = useState("");
  const [[page, direction], setPage] = useState([0, 0]);

  const NEXT = [13, 32, 39, 38];
  const PREV = [37, 40];

  const navigate = ({ keyCode }) => {
    if (PREV.indexOf(keyCode) !== -1) {
      setKeyPress(keyCode);
      document.getElementById(keyCode).style.background = "#FFFFFF80";
      setKeyName("BACK");
      paginate(-1);
    }
    if (NEXT.indexOf(keyCode) !== -1) {
      setKeyPress(keyCode);
      document.getElementById(keyCode).style.background = "#FFFFFF80";
      setKeyName("NEXT");
      paginate(1);
    }
  };

  const remove = () => {
    if (PREV.indexOf(keyPress) !== -1 || NEXT.indexOf(keyPress) !== -1) {
      document.getElementById(keyPress).style.background = "none";
    }
    setKeyPress("");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setKeyName("");
    }, 4000);
    return () => clearInterval(interval);
  }, [keyPress]);

  useEventListener("keydown", navigate);
  useEventListener("keyup", remove);

  function wrap(start, total, iterator) {
    if (direction === 1 && iterator === total) {
      setPage([0, direction]);
      return start;
    }
    if (direction === -1 && page === -1) {
      setPage([total - 1, direction]);
      return total - 1;
    }
    return page;
  }
  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  const images = [
      "#336699",
      "#FF4422",
      "#C4123C",
      "#f4fb56"
  ];

  const imageIndex = wrap(0, images.length, page);

  //console.log(direction)

  return (
    <div className="info-container">
      <div className="info-body">
        <h2>Presentation Directions</h2>
        <div className="info-body-columns">
          <div className="column-one"><Link to={'/materials'}>Material List</Link></div>
          <div className="column-two">
            <h3>Matlib Presentation Tool</h3>
            <p>This is an interface for presenting materials from the Matlib Digital Library platform. Materials are integrated throughout this presentation to optimize for time and flexiblity.</p>
            <p>This is a beta version and there's still some bugs in the system.</p>
            <p>Future version will also have a mobile companion feature allowing users to follow along in presentations</p>
          </div>
          <div className="column-three">
            <div className="keyboard-directions">
              <h2>Keyboard Navivation</h2>
              <h4>Use the keyboard to move through the deck.</h4>
              <div className="arrows">
                <div>
                  {" "}
                  <div id="37" className="keys">
                    &#9668;
                  </div>
                </div>
                <div>
                  <div id="38" className="keys">
                    &#9650;
                  </div>
                  <div id="40" className="keys">
                    &#9660;
                  </div>
                </div>
                <div>
                  <div id="39" className="keys">
                    &#9658;
                  </div>
                </div>
              </div>
              <div
                id="13"
                className="keys"
                style={{ width: "auto", padding: "0 20px", height: "50px" }}
              >
                ENTER
              </div>
              <div
                id="32"
                className="keys"
                style={{ width: "auto", padding: "0 70px", height: "50px" }}
              >
                SPACE BAR
              </div>
              <div className="keyboard-direction-screen">
                <AnimatePresence initial={false} custom={direction}>
                  <motion.div
                    key={page}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                  >
                    <div className="directions-slider"  style={{background: images[imageIndex]}}>
                      {keyName}
                    </div>
                  </motion.div>
                </AnimatePresence>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="directions-footer">
        
      </div>
    </div>
  );
}

export default Directions;

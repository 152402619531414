import React from 'react'
import { motion } from 'framer-motion'

function Grow({children}) {
    return (
        <motion.div
            drag
            initial={{ scale: 1, opacity:1 }}
            animate={{ scale: 1.1, opacity:1 }}
            transition={{

                duration:0.4
            }}
            >
            {children}
        </motion.div>
    )
}

export default Grow

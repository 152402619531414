import React, {useState, useEffect, useContext} from 'react'
import { motion } from 'framer-motion'
import {closeIcon, circleIcon } from './Icons'
import { MaterialContext } from "../context/MaterialContext";


function Modal() {

    const { itemData } = useContext(MaterialContext);

 

    const [ popUp, setPopup ] = useState(false)
    const [ imageIndex, setImageIndex ] = useState(0)
    const [ imageLoaded, setImageLoaded ] = useState(false)

    const handleImageChange = (index) => {
        setImageLoaded(false);
        setImageIndex(index)
    }

    const handlePopUp = (item) => {

        setPopup(true)
        setImageLoaded(false)
        setImageIndex(0);
    }

    useEffect(() => {
        if(itemData){
            handlePopUp(itemData)
        } 
        
    }, [itemData]);
    // const handleNext = () => {
    //     const count = pageItems.length
    //     let article = pageItems[articleIndex + 1]
    //     let index = articleIndex + 1;
    //     if(index !== count){
    //         handlePopUp(article, index)
    //     }
    // }

    if(itemData.id && popUp){
    return (
        <div className="modal-background" onClick={() => setPopup(false)}>
           <motion.div 
           initial={{opacity: 1, y: 0}}
           animate={{ opacity:1, y: 0 }}
           exit={{ opacity: 0, y:0 }}
           transition={{ ease: "easeOut", duration: 0.05 }}
           className="modal-window"  onClick={event => event.stopPropagation()}>
              <span className="modal-close" onClick={() => setPopup(false)}>{closeIcon}</span>
              <div className="modal-window-image">
                   {itemData && <img src={itemData.gallery_medium[imageIndex]} onLoad={() => setImageLoaded(true)} style={{filter: `blur(${imageLoaded ? '0px' : '5px'})`}} alt={itemData.title}/>}
                   <div className="large-image-loader">{!imageLoaded && circleIcon}</div>

               </div>
               
                <div className="large-info-content" style={{filter: 'blur(0px)'}}>
        {itemData &&
            <>
                <h3>{itemData.title.toUpperCase()}</h3>
                <h4>{itemData.description}</h4>
                <div style={{marginTop:'10px'}} className="large-image-radios-box">
                    {itemData.gallery_large.map((img, index) =>  <div key={index} data-image={img[index]} className="large-image-radios" onClick={() => handleImageChange(index)} 
                    style={{background: imageIndex === index ? '#F4FB5680' : 'none'}}></div>)}
                </div>
               <span className="price"><h4>${itemData.price} / sq.ft.</h4></span>
                
            </>}
        </div>
           </motion.div>
        </div>
    )}
    return null
}

export default Modal

import React, {useState, useEffect} from 'react'
import slideInfo from '../data/materialSlides.json'
import testData from '../data/matlibData.json'
import kindLogo from '../images/kindLogo.png'
import { useCurrentSlide } from "../context/CurrentSlideContext";
import QRCode from 'qrcode';
import {motion, AnimatePresence } from 'framer-motion'

var opts = {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    quality: 0.3,
    margin: 1,
    color: {
      dark:"#010599FF",
      light:"#FFBF60FF"
    }
  }

function MaterialSlide({slide}) {

    const { currentSlide } = useCurrentSlide();

    
    const slideData = testData[slide];
    const slideDetails = slideInfo[slide];


    const [largeImage, setLargeImage ] = useState([])
    const [imageIndex, setImageIndex ] = useState(0)
    const [imageLoaded, setImageLoaded ] = useState(false)
    const [qrCode, setQrCode ] = useState('')

    useEffect(() => {
      setLargeImage(slideData[0])
      setImageLoaded(false);
      QRCode.toDataURL(`https://matlib.co/m/${slideData[0].slug}`, opts,).then(setQrCode);
    }, [currentSlide, slideData]);


    const handleArticleChange = (data) => {
        setImageLoaded(false);
        setImageIndex(0)
        setLargeImage(data)
        QRCode.toDataURL(`https://matlib.co/m/${data.slug}`, opts).then(setQrCode);
    }
    const handleImageChange = (index) => {
        setImageLoaded(false);
        setImageIndex(index)
    }

    const circleIcon = <svg className="circle-loader" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 40 40" width="60" height="60" >
        <path style={{fill:'#E11B4E'}} d="M39,20c0,10.5-8.5,19-19,19v1c11,0,20-9,20-20H39z"/>
        <path style={{fill:'#686868'}} d="M20,1c10.5,0,19,8.5,19,19h1C40,9,31,0,20,0l0,0C9,0,0,9,0,20s9,20,20,20v-1C9.5,39,1,30.5,1,20S9.5,1,20,1"/>
    </svg>

    return (
        <AnimatePresence exitBeforeEnter>
        <motion.div
            key={slide}
            initial={{ opacity: 0, y:-10 }}
            animate={{ opacity: 1, y:0 }}
            transition={{ ease: "easeOut", duration: 0.2 }}
            exit={{ opacity: 0, y:10 }} 
        
        className="slide-container">
            <div className="container-large">
         
            <div className="large-image">
                   {largeImage && <img src={largeImage.gallery_large[imageIndex]} onLoad={() => setImageLoaded(true)} style={{height:'100%', width:'inherit', filter: `blur(${imageLoaded ? '0px' : '5px'})`}} alt={largeImage.title}/>}
                </div>
                <div className="large-image-loader">{!imageLoaded && circleIcon}</div>
                {largeImage.type === 'cowhide' ? <div className="kind-leather-badge"><img src={kindLogo} alt="kind leather"/></div>  : null}
                <div className="qr-code"><img src={qrCode} alt="qr code" /></div>
                <div className="large-info-content" style={{filter: 'blur(0px)'}}>
                {largeImage &&
                    <>
                        <h5>{largeImage.title}</h5>
                        <h6>{largeImage.description}</h6>
                       <span className="price">${largeImage.price} / sq.ft.</span>
                        

                        <div style={{marginTop:'10px'}} className="large-image-radios-box">
                            {largeImage.gallery_large.map((img, index) =>  <div key={index} data-image={img[index]} className="large-image-radios" role = "button" aria-hidden="true" onClick={() => handleImageChange(index)} 
                            style={{background: imageIndex === index ? '#F4FB5680' : 'none'}}></div>)}
                        </div>
                    </>}
                </div>

               
               
            </div>
            <div className="right-sidebar-container">
                <div className="sidebar-title">{slideDetails[0].description}</div>
                {slideData.map( (item, index) =>
                    <div role = "button" key={index} className="sidebar-image-container" aria-hidden="true" onClick={() => handleArticleChange(item)} 
                    style={{border: item.id === largeImage.id ? 'none': 'none', transform: item.id === largeImage.id ? 'rotateZ(0deg) translateX(-10px)' : 'none'}}>
                        <div className="sidebar-image-name">{item.title}</div>
                        <img src={item.gallery_swatch[0]} alt={item.title}/>
                    </div>
                )}
            </div>
        </motion.div>
        </AnimatePresence>
    )
}

export default MaterialSlide
